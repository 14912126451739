<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>报表管理</el-breadcrumb-item>
      <el-breadcrumb-item>{{title}}</el-breadcrumb-item>
    </el-breadcrumb>
    <h2>{{tabletitle}}</h2>
    <div class="add">
      <!-- <el-input placeholder="请输入报表ID" v-model="reportId" size="small" style="width:120px;"></el-input>
      <el-button type="primary" style="margin:0 10px;" @click="getheader()" size="small">查询报表</el-button> -->
      
      <!-- 筛选条件 -->
      <span v-for="(item,index) in queryform" :key="index" class="queryline">
        <!-- type为3原样返回 -->
        <el-input v-if="item.Type === 0" :placeholder="item.Value" v-model="item.value" size="small" style="width:120px;"></el-input>
        <el-input v-if="item.Type === 3" v-show="false" :placeholder="item.Value" v-model="item.value" size="small" style="width:120px;"></el-input>
        <el-select v-if="item.Type === 1" v-model="item.value" :placeholder="item.Value" style="width:130px;" size="small">
          <el-option v-for="b in item.option" :key="b.id" :label="b.label" :value="b.value">
          </el-option>
        </el-select>
        <el-date-picker v-if="item.Type === 2" v-model="item.value" type="date" :placeholder="item.Value" format="yyyy-MM-dd" value-format="yyyy-MM-dd" clearable size="small" style="width:130px;">
        </el-date-picker>
      </span>
      <el-button type="primary" @click="getlist(1)" size="small">查询</el-button>
      <el-button type="primary" @click="exportorder" size="small">导出Excel</el-button>
    </div>
    
    <div id="writetable" v-if="showtable">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column v-for="(item,index) in headerdata" :key="index" :label="item.value" :prop="item.key" align="center">
        </el-table-column>
      </el-table>
      <p class="paging">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current" :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalCount" :hide-on-single-page="value"></el-pagination>
      </p>
    </div>
  </div>
</template>
<script>
var queryjson = "";
export default {
  inject: ["reload"],
  data() {
    return {
      reportId: "", //报表id
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [20], // 个数选择器（可修改）
      PageSize: 20, // 默认每页显示的条数（可修改）
      value: false,
      headerdata: [], //表头数据
      queryform: [], //查询内容
      tableData: [], //表格数据
      querytype: "", //查询类型
      showtable: false,
      title: "",
      ischaxun: false,
      tabletitle:'',
    };
  },
  watch: {
    $route(o, n) {
      if (this.$route.query.id) {
        this.reload();
      }
    },
  },
  mounted() {
    this.reportId = this.$route.query.id;
    this.title = this.$route.query.name;
    this.getheader();
  },
  methods: {
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.getlist(currentPage);
    },
    getheader() {
      this.axios
        .get(
          this.serveurl.mallserve1 +
            "/api/admin/Report/get_header?reportId=" +
            this.reportId
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.showtable = true;
            //返回查询条件
            var headerdata = JSON.parse(res.data.data.headers);
            //处理表头格式
            for (var key in headerdata) {
              this.headerdata.push({ key: key, value: headerdata[key] });
            }
            this.tabletitle = res.data.data.name;
            //查询条件
            if (res.data.data.queryForm) {
              var queryform = JSON.parse(res.data.data.queryForm);
              if (queryform.length > 0) {
                this.queryform = queryform;
                this.querytype = queryform[0].Type;
              }
            }
          } else {
            this.showtable = false;
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },
    getlist(page) {
      var str = "&param=",
        json = {};
      if (this.queryform.length > 0) {
        for (let a = 0; a < this.queryform.length; a++) {
          let b = this.queryform[a].Key;
          if (this.queryform[a].value) {
            json[b] = this.queryform[a].value;
          } else {
            if(this.queryform[a].Type == 3){
              json[b] = this.queryform[a].Value;
            }
            if (this.queryform[a].Type !== 0 && this.queryform[a].Type !== 3) {
              this.$message({
                message: "请完善查询条件",
                type: "error",
              });
              return;
            }
          }
        }
        queryjson = JSON.stringify(json);
      } else {
        queryjson = "";
      }
      this.axios
        .get(
          this.serveurl.mallserve1 +
            "/api/admin/Report/get_data?reportId=" +
            this.reportId +
            "&index=" +
            page +
            "&pagesize=" +
            this.PageSize +
            str +
            queryjson
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.tableData = res.data.data.list;
            this.totalCount = res.data.data.count;
            this.ischaxun = true;
          }else{
            this.ischaxun = false;
          }
        });
    },
    exportorder() {
      if (this.ischaxun) {
        var dom = document.createElement("a");
        //下载文件的路径
        dom.href =
          this.serveurl.mallserve1 +
          "/api/admin/Report/export?reportId=" +
          this.reportId +
          "&param=" +
          queryjson;
        //对下载的文件命名
        dom.download = "";
        dom.click();
      } else {
        this.$message({
          message: "请先进行查询操作",
          type: "error",
        });
      }
    },
  },
};
</script>
<style>
.add {
  width: 100%;
  height: 50px;
  text-align: left;
  float: left;
}
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}
.queryline {
  margin-right: 10px;
}
.queryline .el-input {
  width: 100%;
}
</style>